.player-stats-table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
  min-width: 600px;
  font-size: 20px;
  font-family: 'Inter', Arial, sans-serif;
  max-width: 1200px;
  margin: 0;
}

.country-flag {
  width: 20px;
  height: 20px;
}

.sort-arrow {
  font-size: 0.75em;
  margin-left: 5px;
  color: rgb(198, 140, 252);
  display: inline-block;
  position: absolute;
}

.th-wrapper {
  position: relative;
  display: inline-block;
}

.player-stats-table th {
  padding: 10px;
  background-color: #333;
  color: #fff;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.player-stats-table td {
  padding: 8px;
  text-align: center;
}

.stats-even-row {
  background-color: #eeeeee;
}

.stats-odd-row {
  background-color: #ffffff;
}

.player-stats-table tr:hover {
  background-color: #6c3fe7;
}

.player-stats-table tr:hover td {
  color: #ffd700;
}

.player-stats-table td,
.player-stats-table th {
  min-width: 50px;
}

.stats-table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.sorted-column {
  background-color: #d1b3e9c7;
}

.stats-sticky-column {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  left: 0;
  background-color: inherit;
  z-index: 1;
}

.stats-sticky-name-column {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  left: 50px;
  background-color: inherit;
  z-index: 1;
}

@media screen and (min-width: 800px) {
  .stats-table-responsive-wrapper {
    display: flex;
    justify-content: center;
    overflow-x: auto;
    white-space: nowrap;
  }
}

@media screen and (max-width: 768px) {
  .player-stats-table td,
  .player-stats-table th {
    padding: 8px;
  }

  .stats-table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-wrapper {
    overflow-x: auto;
  }

  .stats-sticky-name-column {
    position: -webkit-sticky; /* For Safari */
    position: sticky !important;
    left: 50px;
    background-color: inherit;
    z-index: 1;
    min-width: 150px !important;
  }

  .player-stats-table {
    width: 100%;
    table-layout: auto;
    font-size: 14px;
    min-width: unset;
  }
}
