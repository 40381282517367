@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import '../../styles/variables.scss';

%card {
  align-items: center;
  backface-visibility: hidden;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  position: absolute;
  transition: transform 0.6s ease;
  width: 100%;
}

.player-card {
  border-radius: 10px;
  cursor: pointer;
  height: 350px;
  perspective: 1000px;
  position: relative;
  width: 231px;

  @media only screen and (max-width: $phone) {
    height: calc(350px * 0.5);
    width: calc(231px * 0.5);
  }

  .player-img {
    height: 100%;
    overflow: hidden;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .card-name-bar {
    background-color: #1a1d24b4;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    width: 40px;
    @media only screen and (max-width: $phone) {
      width: calc(40px * 0.5);
    }

    .league-logo {
      position: absolute;
      top: 5px;
      width: 40px;
      @media only screen and (max-width: $phone) {
        width: calc(40px * 0.5);
      }
    }

    .card-name-container {
      align-items: center; // Center the name horizontally
      justify-content: center; // Center the name vertically
      color: white;
      display: flex;
      font-weight: 500;
      height: 100%; // Ensure the container takes full height
      writing-mode: tb-rl;
      transform: rotate(-180deg);
      white-space: nowrap;

      .card-position {
        @media only screen and (max-width: $phone) {
          font-size: 5px;
        }
      }
      .card-name {
        font-family: 'Anton', sans-serif;
        font-size: 22px;
        font-weight: 100;
        font-style: normal;
        @media only screen and (max-width: $phone) {
          font-size: 15px;
        }
      }
    }

    .card-team-logo {
      width: 34px;
      @media only screen and (max-width: $phone) {
        width: calc(34px * 0.5);
      }
    }
    .logo-container {
      align-items: center;
      background-color: #00000057; /* Black background for the logo area */
      border: 2px solid #ffffff63; /* White border around the logo */
      border-radius: 50%; /* Makes the container circular */
      bottom: 6px;
      display: flex;
      height: 40px;
      justify-content: center;
      position: absolute;
      width: 40px; /* Size of the circular container */

      @media only screen and (max-width: $phone) {
        height: calc(40px * 0.5);
        width: calc(40px * 0.5);
      }
    }
  }

  &:hover {
    transform: scale(1.05);
  }

  .card-front {
    @extend %card;
    background-color: #1a1d24;
    flex-direction: row;
  }

  .card-back {
    @extend %card;
    align-items: flex-start;
    background-color: #1a1d24;
    flex-direction: column;
    padding: 10px;
    transform: rotateY(180deg);

    .name-title {
      color: white;
      font-size: 18px;
      font-weight: 600;
    }
    span {
      color: white;
    }
  }

    @media only screen and (max-width: $phone) {
      height: calc(350px * 0.5);
      width: calc(231px * 0.5);
    
  
    .card-back {
      padding: 5px; // Reduce padding for smaller cards
      font-size: 12px; // Scale down text size in mobile
      line-height: 1.2; // Adjust line height for better spacing
      
      span, .name-title {
        font-size: 12px; // Ensure smaller text for details
      }
  
      hr {
        margin: 5px 0; // Reduce spacing for horizontal line
      }
    }
    }
  
  &.flipped {
    .card-front {
      transform: rotateY(180deg);
    }

    .card-back {
      transform: rotateY(0deg);
    }
  }
}
