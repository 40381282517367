.attendance-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #23184b;
}

.attendance-card {
  width: 24rem;
  background-color: #343a40;
  color: white;
  padding: 20px;
}

.no-game-text {
  color: white;
  text-align: center;
}

.attendance-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
