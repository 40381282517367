* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home-page {
  display: flex;
  flex-direction: column;
  height: auto;
}

.content-section {
  background-color: #f8f8f8;
  padding: 40px;
  min-height: calc(100vh - 50vh);
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  align-items: flex-start;
  padding: 80px 20px;
  position: relative; /* To position the ::before element correctly */
  overflow: hidden;
}

/* Next game table  */
.next-game-section {
  padding: 50px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: flex-start; /* Align content to the top */
  align-items: flex-start; /* Align items to the left */
}

.next-game-section p {
  color: #00000091;
}

.next-game-table {
  width: 100%;
  margin: 0 auto;
  margin-left: 0;
  margin-right: 0;
}

.next-game-table td {
  padding: 10px;
  text-align: center;
}

.next-game-table tr:nth-child(even) {
  background-color: #afaeae; /* Optional: Alternating row color */
}

.section-divider {
  border-top: 2px solid #979797; /* A light grey line */
  margin: 1px auto; /* Adds spacing above and below the line */
  width: 95%;
  margin-top: 50px;
}

.table-button-wrapper {
  display: flex;
  flex-direction: column; /* Stack the table and content vertically */
  justify-content: flex-end; /* Align all content towards the bottom */
  position: relative; /* So we can position the button at the bottom right */
  width: 40%; /* Adjust as necessary */
  margin: 0 auto; /* Center the container */
}

.add-to-calendar-button {
  position: absolute; /* Absolute positioning to place the button at the bottom right */
  bottom: 2px; /* Space from the bottom */
  right: 10px;
  display: inline-block;
  padding: 5px 10px;
}

.calendar-button-container {
  text-align: center;
}

.table-container {
  background-color: #ffffff; /* White background for the box */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Ensures border-radius applies properly */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  width: 40%;
}

.image-container {
  padding: 20px;
  text-align: center;
  border-top-left-radius: 10px; /* Rounded top-left corner */
  border-top-right-radius: 10px; /* Rounded top-right corner */
  background: linear-gradient(to right, #380079, #fdde2d);
}

.image-container img {
  max-width: 15%; /* Scale image to 80% of the container's width */
  height: auto;
  border-radius: 5px; /* Optional: Image rounding */
}
/* Next game end */

.header-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  min-height: 600px;
  text-align: center;
  color: #ffffff;
}

.header-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./../../../public/images/rink5.jpg');
  background-size: cover;
  background-position: center;
  filter: brightness(0.5);
  z-index: -1;
}

.schedule-table-container {
  width: 100%;
}

.schedule-table-header {
  background-color: #f0f0f0;
  text-align: center;
  font-weight: bold;
  width: 100%;
  max-width: 1000px;
  padding: 5px;
  font-size: 24px;
}

@media (max-width: 768px) {
  .header-section {
    height: 30vh;
    min-height: 200px;
  }

  .header-section::before {
    background-position: top;
  }

  .header-section .logo {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .header-section h1 {
    font-size: 28px;
    margin-bottom: 5px;
  }

  .header-section p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .header-section {
    height: 25vh;
    min-height: 180px;
  }

  .header-section::before {
    background-position: top;
  }

  .header-section .logo {
    font-size: 18px;
  }

  .header-section h1 {
    font-size: 24px;
  }

  .header-section p {
    font-size: 12px;
  }

  .table-button-wrapper {
    display: flex;
    flex-direction: column; /* Stack the table and content vertically */
    justify-content: flex-end; /* Align all content towards the bottom */
    position: relative; /* So we can position the button at the bottom right */
    width: 95%;
    margin: 0 auto; /* Center the container */
    font-size: small;
  }

  .add-to-calendar-button {
    position: absolute; /* Absolute positioning to place the button at the bottom right */
    bottom: 2px; /* Space from the bottom */
    right: 5px;
  }
  .next-game-section {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    justify-content: flex-start; /* Align content to the top */
    align-items: flex-start; /* Align items to the left */
  }
}

@media (max-width: 384) {
  /* Next game table  */
  .next-game-section {
    text-align: center;
    display: inline-block;
    padding: 20px;
    border: 2px solid #ddd;
    border-radius: 8px;
    border: 2px solid #ddd;

    font-size: medium;
  }

  .next-game-table {
    font-size: medium;
  }

  .table-container {
    background-color: #ffffff; /* White background for the box */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Ensures border-radius applies properly */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    width: auto;
  }

  .image-container {
    padding: 20px;
    text-align: center;
    border-top-left-radius: 10px; /* Rounded top-left corner */
    border-top-right-radius: 10px; /* Rounded top-right corner */
    background: linear-gradient(to right, #380079, #d3c71e);
  }

  .image-container img {
    max-width: 15%; /* Scale image to 80% of the container's width */
    height: auto;
    border-radius: 5px;
  }
}

@media (max-width: 430px) {
  /* Next game table  */
  .next-game-section {
    text-align: center;
    display: inline-block;
    padding: 20px;
    font-size: medium;
  }

  .next-game-table {
    font-size: medium;
  }

  .table-container {
    background-color: #ffffff; /* White background for the box */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Ensures border-radius applies properly */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    width: auto;
  }

  .image-container {
    padding: 20px;
    text-align: center;
    border-top-left-radius: 10px; /* Rounded top-left corner */
    border-top-right-radius: 10px; /* Rounded top-right corner */
    background: linear-gradient(to right, #380079, #d3c71e);
  }

  .image-container img {
    max-width: 15%; /* Scale image to 80% of the container's width */
    height: auto;
    border-radius: 5px; /* Optional: Image rounding */
  }

  .table-button-wrapper {
    display: flex;
    flex-direction: column; /* Stack the table and content vertically */
    justify-content: flex-end; /* Align all content towards the bottom */
    position: relative; /* So we can position the button at the bottom right */
    width: 100%; /* Adjust as necessary */
    margin: 0 auto; /* Center the container */
    font-size: small;
  }

  .next-game-section {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    justify-content: flex-start; /* Align content to the top */
    align-items: flex-start; /* Align items to the left */
  }

  .table-button-wrapper h3,
  .table-button-wrapper p {
    margin: 0; /* Remove any default margins */
    padding-bottom: 5px;
  }

  .text-container {
    display: flex; /* Use flexbox for aligning items */
    flex-direction: column; /* Stack the text elements vertically */
    justify-content: flex-start; /* Align items to the top */
    align-items: flex-start; /* Align items to the left */
  }
}

@media (prefers-color-scheme: dark) {
  .content-section {
    background-color: #111111;
    color: #d1d1d1;
    padding: 40px;
    min-height: calc(100vh - 50vh);
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    align-items: flex-start;
    padding: 40px 20px;
  }

  .h2 {
    padding-bottom: 20px;
  }

  /* Next game table  */
  .next-game-section {
    background-color: #111111;
  }

  .next-game-section p {
    color: #ebe8e891;
  }

  .next-game-table tr:nth-child(even) {
    background-color: #afaeae;
  }

  .section-divider {
    border-top: 2px solid #979797;
  }

  .add-to-calendar-button:hover {
    background-color: #45a049; /* Darker green on hover */
  }

  .calendar-button-container {
    text-align: center;
  }

  .table-container {
    background-color: #111111; /* White background for the box */
  }

  .image-container {
    border-top-left-radius: 10px; /* Rounded top-left corner */
    border-top-right-radius: 10px; /* Rounded top-right corner */
    background: linear-gradient(to right, #380079, #fdde2d);
  }
}
