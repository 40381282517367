/* src/components/InfoBox.css */

.post-card {
  display: inline-block;
  width: auto;
  min-width: auto; /* Minimum width to prevent it from becoming too narrow */
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #f1f1f1; /* Light background */
  color: #23184b;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 3px 15px rgba(177, 177, 177, 0.87);
  text-align: center;
  margin: 10px;
}

.post-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

.post-card-image {
  width: 200px;
  height: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px 8px 0 0;
  margin-bottom: 10px; /* Reduced margin for tighter alignment */
}

.post-card-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  color: #23184b;
}

.post-card-excerpt {
  font-size: 16px;
  color: #3a3a3a;
  margin-bottom: 10px;
  overflow-wrap: break-word;
  word-break: break-word;
  flex-grow: 1; /* Allow this section to grow and take up space if needed */
}

.post-card-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  color: #3a3a3a;
}

/* Common Table Styles for Mini-Tables */
.player-stats-mini-table,
.standings-mini-table,
.upcoming-games-mini-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  color: #000000;
  box-shadow: 0 3px 15px rgba(219, 219, 219, 0.87);
  border-radius: 8px;
}

.player-stats-mini-table th,
.player-stats-mini-table td,
.standings-mini-table th,
.standings-mini-table td,
.upcoming-games-mini-table th,
.upcoming-games-mini-table td {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #b9b9b9;
}

.player-stats-mini-table th,
.standings-mini-table th,
.upcoming-games-mini-table th {
  font-weight: bold;
  background-color: #999999; /* Matches table header color */
}

.player-stats-mini-table tr:nth-child(even),
.standings-mini-table tr:nth-child(even),
.upcoming-games-mini-table tr:nth-child(even) {
  background-color: #c0bdbd; /* Matches even row color */
}

/* Table Header Rounded Corners */
.upcoming-games-mini-table th:first-child,
.player-stats-mini-table th:first-child,
.standings-mini-table th:first-child {
  border-top-left-radius: 8px;
}

.upcoming-games-mini-table th:last-child,
.player-stats-mini-table th:last-child,
.standings-mini-table th:last-child {
  border-top-right-radius: 8px;
}

/* Table Last Row Rounded Corners */
.upcoming-games-mini-table tr:last-child td:first-child,
.player-stats-mini-table tr:last-child td:first-child,
.standings-mini-table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.upcoming-games-mini-table tr:last-child td:last-child,
.player-stats-mini-table tr:last-child td:last-child,
.standings-mini-table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

/* Dark Mode Styling */
@media (prefers-color-scheme: dark) {
  .post-card {
    background-color: #111111;
    color: #ffffff;
    box-shadow: 0 3px 15px #000000de;
  }

  .post-card-title {
    color: #fff;
  }

  .post-card-excerpt {
    color: #bbb;
  }

  .post-card-footer {
    color: #aaa;
  }

  /* Dark mode for mini-tables */
  .player-stats-mini-table,
  .standings-mini-table,
  .upcoming-games-mini-table,
  .standings-scroll {
    color: #ffffff;
    box-shadow: 0 3px 15px #000000de;
  }

  .player-stats-mini-table th,
  .player-stats-mini-table td,
  .standings-mini-table th,
  .standings-mini-table td,
  .upcoming-games-mini-table th,
  .upcoming-games-mini-table td {
    border-bottom: 1px solid #1a1a1ade;
  }

  .player-stats-mini-table th,
  .standings-mini-table th,
  .upcoming-games-mini-table th {
    background-color: #646464; /* Matches dark mode header color */
  }

  .player-stats-mini-table tr:nth-child(even),
  .standings-mini-table tr:nth-child(even),
  .upcoming-games-mini-table tr:nth-child(even) {
    background-color: #272727; /* Matches dark mode even row color */
  }
}

/* S24Ultra CSS */
@media (max-width: 384px) {
  .player-stats-mini-table th,
  .player-stats-mini-table td,
  .upcoming-games-mini-table th,
  .upcoming-games-mini-table td,
  .standings-mini-table th,
  .standings-mini-tabletd {
    padding: 5px;
    font-size: auto;
    text-overflow: ellipsis;
    max-width: 4ch;
    overflow: hidden;
    white-space: nowrap;
    font-size: medium;
    overflow-x: scroll;
  }

  .player-stats-mini-table th,
  .player-stats-mini-table td,
  .upcoming-games-mini-table th,
  .upcoming-games-mini-table td {
    max-width: 9ch; /*if the words go over 9 characters then it turns into ...  */
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Iphone 14 PRO MAX CSS*/
@media (max-width: 430px) {
  .player-stats-mini-table th,
  .player-stats-mini-table td,
  .upcoming-games-mini-table th,
  .upcoming-games-mini-table td,
  .standings-mini-table th,
  .standings-mini-tabletd {
    padding: 5px;
    font-size: auto;
    text-overflow: ellipsis;
    max-width: 4ch;
    overflow: hidden;
    white-space: nowrap;
    font-size: medium;
  }

  .player-stats-mini-table th,
  .player-stats-mini-table td,
  .upcoming-games-mini-table th,
  .upcoming-games-mini-table td {
    max-width: 9ch; /*if the words go over 9 characters then it turns into ...  */
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
