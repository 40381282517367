.navbar-logo-icon {
  width: 30px;
  height: auto;
  margin-right: 15px;
  margin-left: 15px;
}

.custom-navbar {
  background-color: #23184b;
  border-bottom: 1px solid rgba(169, 169, 169, 0.356);
  font-weight: 500;
  position: sticky !important;
  top: 0 !important;
  z-index: 1020 !important;
}

.navbar-text {
  padding-right: 15px;
}

.custom-navbar .nav-link,
.custom-navbar .navbar-brand-text {
  color: white !important;
  padding-left: 10px;
}

.custom-navbar .nav-link:hover {
  color: #ffd700 !important;
}

/* General mobile adjustments (max-width: 768px) */
@media screen and (max-width: 768px) {
  .navbar-logo-icon {
    margin-left: 10px;
  }
}
