.schedule-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0vh;
  width: 100%;
}

.darker-row {
  color: white !important;
}

.schedule-table-container {
  width: 100%;
  max-width: 1000px;
}

.schedule-table-header {
  background-color: #f0f0f0;
  text-align: center;
  font-weight: bold;
  width: 100%;
  max-width: 1000px;
  padding: 5px;
  font-size: 24px;
}

.game-cards-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0px;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

.game-card {
  display: flex;
  flex-direction: column;
  padding: 15px;
  color: #333333;
}

.game-card.even-row {
  background-color: #f0f0f0;
}

.game-card.odd-row {
  background-color: #ffffff;
}

.game-card .game-time {
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 15px;
}

.game-card .teams {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
}

.game-card .team {
  text-align: center;
  flex: 1;
}

.game-card .team-logo {
  width: 40px;
  height: 40px;
}

.game-card .additional-info {
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-top: 15px;
}

@media (prefers-color-scheme: dark) {
  .darker-row {
    color: #333;
  }

  .schedule-table-header {
    color: #333;
  }
}
