.standings-table-container {
  display: flex;
  max-width: 100%;
  justify-content: center;
  overflow-x: auto;
}

.standings-table-responsive-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: 50%;
  min-width: 1000px;
}

.standings-table {
  width: 100%;
  min-width: 800px;
  border-collapse: collapse;
  height: 700px;
  font-size: 24px;
  cursor: pointer;
  margin: 0;
}

.standings-table th,
.standings-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  z-index: 0;
}

.sticky-column {
  position: sticky;
  background-color: inherit;
  z-index: 1;
}

.sticky-column-1 {
  left: 0;
  min-width: 50px;
}

.sticky-column-2 {
  left: 50px;
  min-width: 150px;
}

.standings-table th {
  padding: 10px;
  background-color: #333;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  z-index: 2;
}

.standings-table td {
  padding: 8px;
  text-align: center;
  color: #333333;
  background-color: inherit;
}

.standings-even-row {
  background-color: #dddddf;
}

.standings-odd-row {
  background-color: #ffffff;
}

.standings-table tr:hover {
  background-color: #6c3fe7;
}

.standings-table tr:hover td {
  color: #ffd700;
}

.error-message {
  color: #ff4c4c;
  font-weight: bold;
  padding: 10px;
}

@media (max-width: 600px) {
  .standings-table-responsive-wrapper {
    overflow-x: auto;
    width: 100%;
    min-width: unset;
  }

  .standings-table {
    width: 100%;
    table-layout: auto;
    font-size: 14px;
  }
}
